<template>
  <div class="quick-links">
    <div class="heading">
      <div class="left-heading">
        <i class="fa fa-shopping-cart"></i>
        <p>Sales Progression | <a href="#">Today's Sales</a></p>
      </div>
      <div class="dropdown">
        <button
          class="dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          Last 7 Days
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">third action</a>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 px-0 pl-md-0 pr-md-2">
          <div class="card sales-card">
            <div class="card-icon">
              <i class="fa fa-user-plus"></i>
            </div>
            <div class="card-body">
              <h6 class="card-title">Customers</h6>
              <h5>{{allData.customer}}</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 px-0 pl-md-0 pr-lg-2 mt-3 mt-md-0">
          <div class="card sales-card">
            <div class="card-icon card-icon-2">
              <i class="fa fa-user-circle"></i>
            </div>
            <div class="card-body">
              <h6 class="card-title">Suppliers</h6>
              <h5>{{allData.supplier}}</h5>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-6 col-lg-4 px-0 pl-md-0 pr-md-2 px-lg-0 mt-3 mt-lg-0"
        >
          <div class="card sales-card">
            <div class="card-icon card-icon-3">
              <i class="fa fa-shopping-bag"></i>
            </div>
            <div class="card-body">
              <h6 class="card-title">Payable Total Amount ({{this.$currency}})</h6>
              <h5>{{allData.payable}}</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 px-0 pl-md-0 pr-lg-2 mt-3">
          <div class="card sales-card">
            <div class="card-icon card-icon-4">
              <i class="fa fa-cart-arrow-down"></i>
            </div>
            <div class="card-body">
              <h6 class="card-title">Receivable Total Amount ({{this.$currency}})</h6>
              <h5>{{allData.receivable}}</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 px-0 pl-md-0 pr-md-2 mt-3">
          <div class="card sales-card">
            <div class="card-icon">
              <i class="fa fa-cart-arrow-down"></i>
            </div>
            <div class="card-body">
              <h6 class="card-title">Order</h6>
              <h5>0</h5>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 col-md-6 col-lg-4 px-0 mt-3">
          <div class="card sales-card">
            <div class="card-icon card-icon-5">
              <i class="fa fa-undo"></i>
            </div>
            <div class="card-body">
              <h6 class="card-title">Refunds</h6>
              <h5>Rs34</h5>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sales",
  props:{
    allData: {
    type:Object
  }
  }
};
</script>

<style></style>
