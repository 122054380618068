<template>
  <div class="quick-links">
    <div class="heading">
      <div class="left-heading">
        <i class="bx bxs-bell"></i>
        <p>Remainders</p>
        <i class="bx bxs-comment-add"></i>
      </div>
      <a href="#" class="custom-btn2">View All</a>
    </div>
    <!-- <div class="alert alert-info alert-dismissible fade show" role="alert">
      <strong> <i class="fa fa-info text-dark"></i> Holy guacamole!</strong
      ><br />
      You should check in on some of those fields below.
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Remainders",
};
</script>

<style></style>
