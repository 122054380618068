<template>
  <div class="quick-links">
    <div class="heading">
      <div class="left-heading">
        <i class="bx bx-link fa-rotate-90"></i>
        <p>Quick Links</p>
      </div>
      <a href="#" class="custom-btn2">Manage Quick Links</a>
    </div>
    <div class="link-content">
      <router-link to="/order" class="link-wrapper ticket-wrapper">
        <i class="fa fa-plus"></i>
        <h6>New Order</h6>
      </router-link>
      <!-- <div class="link-wrapper search-ticket">
        <i class="fa fa-ticket-alt"></i>
        <h6>Search Ticket</h6>
      </div>
      <div class="link-wrapper ticket-presets">
        <i class="fa fa-hourglass-end" aria-hidden="true"></i>
        <h6>Ticket Presets</h6>
      </div>
      <div class="link-wrapper token-wrapper">
        <i class="fa fa-plus"></i>
        <h6>New Token</h6>
      </div> -->

      <router-link to="/add-products" class="link-wrapper product-wrapper">
        <i class="fa fa-plus"></i>
        <h6>New Product</h6>
      </router-link>
      <div class="link-wrapper service-wrapper">
        <i class="fa fa-wrench"></i>
        <h6>Processes</h6>
      </div>
      <!-- <div class="link-wrapper product-search">
        <i class="fa fa-plus"></i>
        <h6>Search Product</h6>
      </div> -->
      <router-link to="/add-customer" class="link-wrapper customer-wrapper">
        <i class="fa fa-user-plus"></i>
        <h6>New Customer</h6>
      </router-link>
      <router-link
        to="/add-supplier"
        class="link-wrapper search-wrapper stock-intake"
      >
        <i class="fa fa-user"></i>
        <h6>New Supplier</h6>
      </router-link>

      <router-link to="/add-manufacturer" class="link-wrapper search-wrapper">
        <i class="fa fa-user"></i>
        <h6>New Manufacturer</h6>
      </router-link>
      <router-link
        to="/create-payment-payable"
        class="link-wrapper customer-purchase"
      >
        <i class="fa fa-shopping-cart"></i>
        <h6>Payment Payable</h6>
      </router-link>
      <!-- <div class="link-wrapper article-wrapper">
        <i class="fa fa-file-alt"></i>
        <h6>Payment </h6>
      </div> -->
      <!-- <div class="link-wrapper token-wrapper">
        <i class="fa fa-money-bill-alt"></i>
        <h6>Used Products Market Price</h6>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickLinks",
};
</script>

<style></style>
