var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quick-links"},[_vm._m(0),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 pl-md-0 pr-md-2"},[_c('div',{staticClass:"card sales-card"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"card-title"},[_vm._v("Customers")]),_c('h5',[_vm._v(_vm._s(_vm.allData.customer))])])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 pl-md-0 pr-lg-2 mt-3 mt-md-0"},[_c('div',{staticClass:"card sales-card"},[_vm._m(2),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"card-title"},[_vm._v("Suppliers")]),_c('h5',[_vm._v(_vm._s(_vm.allData.supplier))])])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 pl-md-0 pr-md-2 px-lg-0 mt-3 mt-lg-0"},[_c('div',{staticClass:"card sales-card"},[_vm._m(3),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"card-title"},[_vm._v("Payable Total Amount ("+_vm._s(this.$currency)+")")]),_c('h5',[_vm._v(_vm._s(_vm.allData.payable))])])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 pl-md-0 pr-lg-2 mt-3"},[_c('div',{staticClass:"card sales-card"},[_vm._m(4),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"card-title"},[_vm._v("Receivable Total Amount ("+_vm._s(this.$currency)+")")]),_c('h5',[_vm._v(_vm._s(_vm.allData.receivable))])])])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading"},[_c('div',{staticClass:"left-heading"},[_c('i',{staticClass:"fa fa-shopping-cart"}),_c('p',[_vm._v("Sales Progression | "),_c('a',{attrs:{"href":"#"}},[_vm._v("Today's Sales")])])]),_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"dropdown-toggle",attrs:{"type":"button","id":"dropdownMenuButton","data-toggle":"dropdown","aria-expanded":"false"}},[_vm._v(" Last 7 Days ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuButton"}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Action")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Another action")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("third action")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"fa fa-user-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-icon card-icon-2"},[_c('i',{staticClass:"fa fa-user-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-icon card-icon-3"},[_c('i',{staticClass:"fa fa-shopping-bag"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-icon card-icon-4"},[_c('i',{staticClass:"fa fa-cart-arrow-down"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6 col-lg-4 px-0 pl-md-0 pr-md-2 mt-3"},[_c('div',{staticClass:"card sales-card"},[_c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"fa fa-cart-arrow-down"})]),_c('div',{staticClass:"card-body"},[_c('h6',{staticClass:"card-title"},[_vm._v("Order")]),_c('h5',[_vm._v("0")])])])])
}]

export { render, staticRenderFns }