<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 pl-0 pr-0 pr-lg-3 mb-3">
              <QuickLinks />
            </div>
            <div class="col-lg-6 pl-0 pr-0 mb-3">
              <Remainders />
            </div>
            <div class="col-12 px-0 mb-3">
              <Sales :allData="allData"/>
            </div>
            <div class="col-12 px-0">
              <Graph />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import QuickLinks from "../components/dashboard/QiuckLinks.vue";
import Remainders from "../components/dashboard/Remainders.vue";
import Sales from "../components/dashboard/Sales.vue";
import Graph from "../components/dashboard/BarChart.vue";
import axios from "axios";

export default {
  name: "Home",

  components: {
    Navbar,
    LeftMenu,
    QuickLinks,
    Remainders,
    Sales,
    Graph,
  },

  data: function() {
    return {
      errorMessage: null,
      allData: {},
      showLoader: 0,
      page: 0,
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      supplier: [],
      customer: [],
      payable: [],
      receivable:[]
    };
  },
methods: {
    fetchAllCounts() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}detailsDashboard`, )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.allData = response.data.data;
            console.log(this.allData)
            this.supplier = response.data.data.supplier;
            this.customer = response.data.data.customer;
            this.payable = response.data.data.payable;
            this.receivable = response.data.data.receivable;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },

  },

  mounted() {
    this.fetchAllCounts();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {},
};
</script>
